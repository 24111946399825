/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import moment from 'moment-timezone';

import payoneer from 'img/icons/payoneer.png';
import skrill from 'img/icons/skrill.png';
import wireTransfer from 'img/icons/wire_transfer.png';
import alarm from 'img/icons/alarm.svg';
import wise from 'img/icons/wise.png';
import pp from 'img/icons/pp.png';

import Icon from 'components/Icon';
import Modal from 'components/Modal';

import UploadButton from 'components/UploadButton';

import modalList from 'constants/modalList';

import { userSetFieldFetch, userClearPaymentError } from 'store/user/actions';
import { closeModalComponent, addModalComponent } from 'store/modals/actions';

import storage from 'utils/localStorageHandler';
import validation from 'utils/validation';
import classNames from 'utils/classNames';
import formatTime from 'utils/formatTime';
import isWriterFromPK from 'utils/isWriterFromPK';
import createAmazonUpload from 'utils/createAmazonUpload';
import getCroppedFileName from 'utils/getCroppedFileName';

import { PaymentsTypeDropdown } from '../Controls';

import WelcomeWTModal from '../WireTransferModals/WelcomeModal';
import WTFormModal from '../WireTransferModals/FormModal';
import ThanksModal from '../WireTransferModals/ThanksModal';

import FilesWarning from '../Modals/FilesWarning';

import WisePKCurrencyBtns from './WisePKCurrencyBtns';
import PaymentMethodFile from './PaymentMethodFile';
import WarningBanner from './WarningBanner';
import PaymentMethodCountryList from './PaymentMethodCountryList';

const methods = {
  SKRILL: 'SKRILL',
  Payoneer: 'PayoneerEmail',
  WireTransfer: 'WireTransfer',
  Wise: 'Wise',
  PAYPAL: 'PAYPAL',
};

const getMethodName = (method) => {
  switch (method) {
    case methods.SKRILL:
      return 'Skrill';
    case methods.Wise:
      return 'Wise';
    case methods.Payoneer:
      return 'Payoneer';
    case methods.WireTransfer:
      return 'Wire transfer';
    case methods.PAYPAL:
      return 'PayPal';
    default:
      return null;
  }
};

class PaymentInfoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payPalValue: '',
      payPalAccountName: '',
      payPalCountry: '',
      skrillValue: '',
      payoneerValue: '',
      payoneerAccountName: '',
      payoneerCountry: '',
      wiseValue: '',
      wiseCountry: '',
      wiseAccountName: '',
      showModal: false,
      showWTWelcomeModal: false,
      showWTFormModal: false,
      showThanksWTModal: false,
      wiseFiles: [],
      payPallFiles: [],
      payoneerFiles: [],
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onSavePayMethod = this.onSavePayMethod.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleWelcomeWireTransferModal = this.toggleWelcomeWireTransferModal.bind(this);
    this.renderWireTransfer = this.renderWireTransfer.bind(this);
    this.toggleFormWireTransferModal = this.toggleFormWireTransferModal.bind(this);
    this.toggleThanksWireTransferModal = this.toggleThanksWireTransferModal.bind(this);
    this.onToggleWarning = this.onToggleWarning.bind(this);
    this.renderWiseForm = this.renderWiseForm.bind(this);
    this.onPaymentFileUpload = this.onPaymentFileUpload.bind(this);
    this.onFileUploadComplete = this.onFileUploadComplete.bind(this);
    this.onFileError = this.onFileError.bind(this);
    this.onFileProgress = this.onFileProgress.bind(this);
    this.onDeleteFile = this.onDeleteFile.bind(this);
  }

  componentDidMount() {
    const {
      payMethodList,
    } = this.props;
    this.setState({
      skrillValue: payMethodList.SKRILL?.value,
      payoneerValue: payMethodList.PayoneerEmail?.value,
      payoneerAccountName: payMethodList.PayoneerEmail?.info?.account_name_surname,
      payoneerCountry: payMethodList.PayoneerEmail?.info?.country,
      wiseValue: payMethodList.Wise?.value,
      wiseCountry: payMethodList.Wise?.info?.country,
      wiseAccountName: payMethodList.Wise?.info?.account_name_surname,
      payPalValue: payMethodList.PAYPAL?.value,
      payPalAccountName: payMethodList.PAYPAL?.info?.account_name_surname,
      payPalCountry: payMethodList.PAYPAL?.info?.country,
      wiseFiles: payMethodList.Wise?.info?.files || [],
      payPallFiles: payMethodList.PAYPAL?.info?.files || [],
      payoneerFiles: payMethodList.PayoneerEmail?.info?.files || [],
    });
  }

  componentDidUpdate(prevProps) {
    const {
      payMethodList,
    } = this.props;

    const {
      payMethodList: prevPayMethodList,
    } = prevProps;
    const { wiseFiles = [], payPallFiles = [], payoneerFiles } = this.state;

    const currentWiseFilesLength = payMethodList.Wise?.info?.files?.length || 0;
    const prevWiseFilesLength = prevPayMethodList.Wise?.info?.files?.length || 0;

    if (prevWiseFilesLength > 0 && currentWiseFilesLength === 0 && wiseFiles.length > 0) {
      this.setState({
        wiseFiles: [],
      });
    }

    const currentPayPallFilesLength = payMethodList.PAYPAL?.info?.files?.length || 0;
    const prevPayPallFilesLength = prevPayMethodList.PAYPAL?.info?.files?.length || 0;

    if (prevPayPallFilesLength > 0 && currentPayPallFilesLength === 0 && payPallFiles.length > 0) {
      this.setState({
        payPallFiles: [],
      });
    }

    const currentPayoneerEmailFilesLength = payMethodList.PayoneerEmail?.info?.files?.length || 0;
    const prevPayoneerEmailFilesLength = prevPayMethodList.PayoneerEmail?.info?.files?.length || 0;

    if (prevPayoneerEmailFilesLength > 0 && currentPayoneerEmailFilesLength === 0 && payoneerFiles.length > 0) {
      this.setState({
        payoneerFiles: [],
      });
    }
  }

  onToggleWarning() {
    const { clearError } = this.props;
    clearError();
  }

  onSavePayMethod(value) {
    const { savePayMethodDropdown, payMethod } = this.props;
    savePayMethodDropdown(value === 'Payoneer' ? 'PayoneerEmail' : value, payMethod);
  }

  onInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  toggleWelcomeWireTransferModal() {
    this.setState({ showWTWelcomeModal: !this.state.showWTWelcomeModal });
  }

  toggleFormWireTransferModal(e, showThanksWTModal = false) {
    this.setState({ showWTFormModal: !this.state.showWTFormModal, showWTWelcomeModal: false, showThanksWTModal });
  }

  toggleThanksWireTransferModal() {
    this.setState({ showThanksWTModal: !this.state.showThanksWTModal });
  }

  onDeleteFile(id, paymentMethod) {
    const onDeleteHandler = file => file.id !== id;

    this.setState((__prevState) => {
      const { wiseFiles, payPallFiles, payoneerFiles } = __prevState;

      if (paymentMethod === 'Wise') {
        return {
          wiseFiles: wiseFiles.filter(onDeleteHandler),
        };
      }

      if (paymentMethod === 'Payoneer') {
        return {
          payoneerFiles: payoneerFiles.filter(onDeleteHandler),
        };
      }

      return {
        payPallFiles: payPallFiles.filter(onDeleteHandler),
      };
    });
  }

  onFileProgress(id, percent, paymentMethod) {
    const progressHandler = (file) => {
      if (file.id === id) {
        return {
          ...file,
          ...{
            progress: percent,
          },
        };
      }
      return file;
    };

    this.setState((__prevState) => {
      const { wiseFiles, payPallFiles, payoneerFiles } = __prevState;

      if (paymentMethod === 'Wise') {
        return {
          wiseFiles: wiseFiles.map(progressHandler),
        };
      }

      if (paymentMethod === 'Payoneer') {
        return {
          payoneerFiles: payoneerFiles.map(progressHandler),
        };
      }

      return {
        payPallFiles: payPallFiles.map(progressHandler),
      };
    });
  }

  onFileUploadComplete(id, paymentMethod) {
    const fileUploadCompleteHandler = (file) => {
      if (file.id === id) {
        return {
          ...file,
          ...{
            progress: false,
            completed: true,
          },
        };
      }
      return file;
    };

    this.setState((__prevState) => {
      const { wiseFiles, payPallFiles, payoneerFiles } = __prevState;

      if (paymentMethod === 'Wise') {
        return {
          wiseFiles: wiseFiles.map(fileUploadCompleteHandler),
        };
      }

      if (paymentMethod === 'Payoneer') {
        return {
          payoneerFiles: payoneerFiles.map(fileUploadCompleteHandler),
        };
      }

      return {
        payPallFiles: payPallFiles.map(fileUploadCompleteHandler),
      };
    });
  }

  onFileError(id, paymentMethod) {
    const fileErrorhandler = (file) => {
      if (file.id === id) {
        return {
          ...file,
          ...{
            progress: false,
            error: true,
          },
        };
      }
      return file;
    };

    this.setState((__prevState) => {
      const { wiseFiles, payPallFiles, payoneerFiles } = __prevState;

      if (paymentMethod === 'Wise') {
        return {
          wiseFiles: wiseFiles.map(fileErrorhandler),
        };
      }

      if (paymentMethod === 'Payoneer') {
        return {
          payoneerFiles: payoneerFiles.map(fileErrorhandler),
        };
      }

      return {
        payPallFiles: payPallFiles.map(fileErrorhandler),
      };
    });
  }

  onPaymentFileUpload(event, paymentMethod) {
    const { deleteModalToState, addModalToState, useId } = this.props;
    // const { wiseFiles, payPallFiles } = this.props;
    const { files } = event.target;

    if (!files || !files.length) return;

    const filesArray = Array.from(files);

    const isSomeFileBig = filesArray.some((file) => {
      const { size } = file;
      const fileSize = size / 1024 ** 2; // in MB
      return fileSize > 50;
    });

    if (filesArray.length > 2 || isSomeFileBig) {
      const dataModal = modalList.FilesWarning;
      const component = <FilesWarning onClick={() => { deleteModalToState(dataModal.name); }} />;
      addModalToState({ component, ...dataModal });
      return;
    }

    filesArray.forEach((file) => {
      const { name, size } = file;
      const id = shortid.generate();
      const createUpload = createAmazonUpload(
        useId,
        file,
        (_s, percent) => { this.onFileProgress(id, percent, paymentMethod); },
        () => {
          this.onFileUploadComplete(id, paymentMethod);
        },
        () => {
          this.onFileError(id, paymentMethod);
        },
      );
      const fileItem = {
        id,
        location: createUpload.location,
        name: getCroppedFileName(name, 38),
        completed: false,
        error: null,
        size,
        owner_role: 'writer',
        created_at: moment().utc().toISOString(),
        progress: true,
      };

      this.setState((__prevState) => {
        const { wiseFiles, payPallFiles, payoneerFiles } = __prevState;

        if (paymentMethod === 'Wise') {
          return {
            wiseFiles: [...wiseFiles, fileItem],
          };
        }

        if (paymentMethod === 'Payoneer') {
          return {
            payoneerFiles: [...payoneerFiles, fileItem],
          };
        }

        return {
          payPallFiles: [...payPallFiles, fileItem],
        };
      });
    });
  }

  cleanUpFiles(files) {
    return files.map((file) => {
      const {
        id, created_at, location, name, size,
      } = file;

      return {
        id,
        created_at,
        location,
        name,
        size,
      };
    });
  }

  renderPayPalForm() {
    const {
      payMethodList, changeLocked, isFetchingFields,
      saveWithInfoMethodValue, countryList,
    } = this.props;
    const {
      payPalValue, payPallFiles = [], payPalAccountName, payPalCountry,
    } = this.state;
    const methodItem = payMethodList.PAYPAL;

    const isSomeFileLoading = payPallFiles.some(file => file.progress);
    const isAllFilesError = payPallFiles.every(file => file.error);

    const isModified = payPalValue !== methodItem.value ||
    ((!methodItem.info || !methodItem.info.files || methodItem.info.files === null) && payPallFiles.length > 0)
    || payPalCountry !== methodItem.info?.country
    || payPalAccountName !== methodItem.info?.account_name_surname;

    const isValid = !!payPalValue
    && validation.isEmail(payPalValue)
    && payPallFiles.length > 0
    && !!payPalCountry
    && !!payPalAccountName
    && validation.firstNameLastName(payPalAccountName)
    && validation.onlyLettersWithSpaceOrHyphen(payPalAccountName);

    const isVerifyBtnDisabled = !isValid || isFetchingFields || isSomeFileLoading || isAllFilesError;
    const isVerifiedOrPending = methodItem.verified || methodItem.pending;
    let showUploadBtn = true;

    if (isVerifiedOrPending || payPallFiles.length >= 2) {
      showUploadBtn = false;
    }

    const onPayPalSubmit = (event) => {
      event.preventDefault();
      if (isVerifyBtnDisabled) return;

      const data = [methods.PAYPAL, payPalValue, {
        files: this.cleanUpFiles(payPallFiles),
        account_name_surname: payPalAccountName,
        country: payPalCountry,
      }];


      saveWithInfoMethodValue(...data);
    };

    return (
      <form onSubmit={onPayPalSubmit} className={classNames('row column row_column_gap_12', { disabled: isVerifiedOrPending, 'opacity-05': methodItem.pending })}>
        <div className="row space-between vertical" style={{ height: '30px' }}>
          <img
            className={classNames(
            'img-payment',
            { pending: methodItem.pending },
            { verified: methodItem.verified },
          )}
            src={pp}
            alt=""
            width="74px"
            height={22}
          />
          <div>
            {isModified &&
              <button
                className="btn btn-bright-success"
                type="submit"
                disabled={isVerifyBtnDisabled}
              >
                Verify
              </button>
            }
            {!isModified && methodItem.verified &&
              <span className="status-box"><Icon className="svg-icon success" iconName="check-fill" />Verified</span>
            }
            {!isModified && methodItem.pending &&
              <span className="status-box"><Icon className="svg-icon" iconName="watch" />In progress</span>
            }
          </div>
        </div>
        <input
          type="text"
          name="payPalAccountName"
          className={classNames('input-text', { 'opacity-05': isVerifiedOrPending })}
          placeholder="Your PayPal Account Name & Surname"
          value={payPalAccountName}
          onChange={this.onInputChange}
          disabled={changeLocked}
        />
        <div className="row vertical column_gap_8">
          <input
            type="text"
            name="payPalValue"
            className={classNames('input-text basis-100', { 'opacity-05': isVerifiedOrPending })}
            placeholder="Your PayPal email"
            value={payPalValue}
            onChange={this.onInputChange}
            disabled={changeLocked}
            style={{ maxWidth: 'calc(50% - 8px)' }}
          />
          <PaymentMethodCountryList
            countries={countryList}
            selectedCountry={payPalCountry || 'Your PayPal Acc. Country'}
            onCountryChange={country => this.setState({ payPalCountry: country })}
            isNoValue={!payPalCountry}
          />
        </div>

        {showUploadBtn && (
          <div className="row vertical column_gap_8">
            <UploadButton
              accept="image/*"
              className="btn btn-light"
              multiple={payPallFiles.length === 0}
              onChange={event => this.onPaymentFileUpload(event, 'PayPall')}
              disabled={isFetchingFields}
            >
              Attach file(s)
            </UploadButton>
            <a
              href="https://drive.google.com/file/d/1SXtWPZ55uqvSCxIHjPgM0Op1HleMhnGF/view"
              target="_blank"
              rel="noopener noreferrer"
              className="pr10 pl10 pt8 pb8"
            >
              Find more
            </a>
          </div>
        )}
        <PaymentMethodFile
          files={payPallFiles}
          onDeleteFile={(_s, id) => this.onDeleteFile(id, 'PayPall')}
          noBtns={isVerifiedOrPending}
          isVerified={methodItem.verified}
        />
      </form>
    );
  }

  renderSkrillStatus() {
    const { payMethodList } = this.props;
    const methodItem = payMethodList.SKRILL;
    if (methodItem.verified) {
      return (<span className="status-box"><Icon className="svg-icon success" iconName="check-fill" />Verified</span>);
    }
    if (methodItem.pending) {
      return (<span className="status-box"><Icon className="svg-icon" iconName="watch" />In progress</span>);
    }
    return null;
  }

  renderSkrillForm() {
    const {
      payMethodList, savePayMethodValue, changeLocked, isFetchingFields,
    } = this.props;
    const { skrillValue } = this.state;
    const methodItem = payMethodList.SKRILL;
    const isModified = skrillValue !== methodItem.value;
    const isValid = !!skrillValue && validation.isEmail(skrillValue);
    return (
      <form onSubmit={(event) => { event.preventDefault(); isModified && isValid && savePayMethodValue(methods.SKRILL, skrillValue); }}>
        <div className="row vertical">
          <div className="col-3 no-padding">
            <input
              type="text"
              name="skrillValue"
              className="input-text"
              placeholder="Enter your Skrill email"
              value={skrillValue}
              onChange={this.onInputChange}
              disabled={changeLocked}
            />
          </div>
          <div className="col-1">
            {isModified &&
              <button className="btn btn-bright-success" type="submit" disabled={!isValid || isFetchingFields}>Verify</button>
            }
            {!isModified && this.renderSkrillStatus()}
          </div>
        </div>
      </form>
    );
  }

  renderWiseStatus() {
    const { payMethodList } = this.props;
    const methodItem = payMethodList.Wise || {};
    if (methodItem.verified) {
      return (<span className="status-box"><Icon className="svg-icon success" iconName="check-fill" />Verified</span>);
    }
    if (methodItem.pending) {
      return (<span className="status-box"><Icon className="svg-icon" iconName="watch" />In progress</span>);
    }
    return null;
  }

  renderWiseForm() {
    const {
      payMethodList, saveWithInfoMethodValue, changeLocked, isFetchingFields,
      isWriterFromPKProp, countryList,
    } = this.props;
    const {
      wiseValue, wiseFiles = [], wiseCountry, wiseAccountName,
    } = this.state;
    const methodItem = payMethodList.Wise;

    const isModified = wiseValue !== methodItem.value ||
    ((!methodItem.info || !methodItem.info.files || methodItem.info.files === null)
    && wiseFiles.length > 0)
    || wiseCountry !== methodItem.info?.country
    || wiseAccountName !== methodItem.info?.account_name_surname;

    const isValid = !!wiseValue
    && validation.isEmail(wiseValue)
    && wiseFiles.length > 0
    && !!wiseCountry
    && !!wiseAccountName
    && validation.firstNameLastName(wiseAccountName)
    && validation.onlyLettersWithSpaceOrHyphen(wiseAccountName);


    const isSomeFileLoading = wiseFiles.some(file => file.progress);
    const isAllFilesError = wiseFiles.every(file => file.error);

    const isVerifyBtnDisabled = !isValid || isFetchingFields || isSomeFileLoading || isAllFilesError;
    const isVerifiedOrPending = methodItem.verified || methodItem.pending;
    let showUploadBtn = true;

    if (isVerifiedOrPending || wiseFiles.length >= 2) {
      showUploadBtn = false;
    }

    const onWiseSubmit = (event) => {
      event.preventDefault();
      if (isVerifyBtnDisabled) return;

      const data = [methods.Wise, wiseValue, {
        files: this.cleanUpFiles(wiseFiles),
        account_name_surname: wiseAccountName,
        country: wiseCountry,
      }];

      saveWithInfoMethodValue(...data);
    };

    return (
      <form onSubmit={onWiseSubmit} className={classNames('row column row_column_gap_12', { disabled: isVerifiedOrPending, 'opacity-05': methodItem.pending })}>
        <div className="row space-between vertical" style={{ height: '30px' }}>
          <div
            className={classNames({
              'row vertical column_gap_8': isWriterFromPKProp,
            })}
          >
            <img
              className={classNames(
            'img-payment',
            { pending: methodItem.pending },
            { verified: methodItem.verified },
          )}
              src={wise}
              alt=""
              width="68px"
              height={19}
            />
            {isWriterFromPKProp && (
              <div className="tooltip-container">
                <Icon className="svg-icon tooltip-trigger" iconName="icon-blue-info" styles={{ width: '14px', height: '14px' }} />
                <p className="tooltip-content right-position">
                  Note: Only USD transfers are <br /> supported. PKR transfers to Wise are <br /> not available.
                </p>
              </div>
            )}
          </div>
          <div>
            {isModified &&
              <button
                className="btn btn-bright-success"
                type="submit"
                disabled={isVerifyBtnDisabled}
              >
                Verify
              </button>
            }
            {!isModified && this.renderWiseStatus()}
          </div>
        </div>
        <input
          type="text"
          name="wiseAccountName"
          className={classNames('input-text', { 'opacity-05': isVerifiedOrPending })}
          placeholder="Your Wise Account Name & Surname"
          value={wiseAccountName}
          onChange={this.onInputChange}
          disabled={changeLocked}
        />
        <div className="row vertical column_gap_8">
          <input
            type="text"
            name="wiseValue"
            className={classNames('input-text basis-100', { 'opacity-05': isVerifiedOrPending })}
            placeholder="Your Wise email"
            value={wiseValue}
            onChange={this.onInputChange}
            disabled={changeLocked}
            style={{ maxWidth: 'calc(50% - 8px)' }}
          />
          <PaymentMethodCountryList
            countries={countryList}
            selectedCountry={wiseCountry || 'Your Wise Acc. Country'}
            onCountryChange={country => this.setState({ wiseCountry: country })}
            isNoValue={!wiseCountry}
          />
        </div>

        {showUploadBtn && (
          <div className="row vertical column_gap_8">
            <UploadButton
              accept="image/*"
              className="btn btn-light"
              multiple={wiseFiles.length === 0}
              onChange={event => this.onPaymentFileUpload(event, 'Wise')}
              disabled={isFetchingFields}
            >
              Attach file(s)
            </UploadButton>
            <a
              href="https://drive.google.com/file/d/1SXtWPZ55uqvSCxIHjPgM0Op1HleMhnGF/view"
              target="_blank"
              rel="noopener noreferrer"
              className="pr10 pl10 pt8 pb8"
            >
              Find more
            </a>
          </div>
        )}
        <PaymentMethodFile
          files={wiseFiles}
          onDeleteFile={(_s, id) => this.onDeleteFile(id, 'Wise')}
          noBtns={isVerifiedOrPending}
          isVerified={methodItem.verified}
        />
      </form>
    );
  }

  renderPayoneerStatus() {
    const { payMethodList } = this.props;
    const methodItem = payMethodList.PayoneerEmail || {};
    if (methodItem.verified) {
      return (<span className="status-box"><Icon className="svg-icon success" iconName="check-fill" />Verified</span>);
    }
    if (methodItem.pending) {
      return (<span className="status-box"><Icon className="svg-icon" iconName="watch" />In progress</span>);
    }
    return null;
  }

  renderPayoneerForm() {
    const {
      changeLocked, payMethodList, isFetchingFields, saveWithInfoMethodValue,
      countryList,
    } = this.props;
    const {
      payoneerValue, payoneerFiles = [], payoneerAccountName, payoneerCountry,
    } = this.state;

    const methodItem = payMethodList.PayoneerEmail;

    const isModified = payoneerValue !== methodItem.value ||
    ((!methodItem.info || !methodItem.info.files || methodItem.info.files === null)
    && payoneerFiles.length > 0)
    || payoneerCountry !== methodItem.info?.country
    || payoneerAccountName !== methodItem.info?.account_name_surname;

    const isValid = !!payoneerValue
    && validation.isEmail(payoneerValue)
    && payoneerFiles.length > 0
    && !!payoneerCountry
    && !!payoneerAccountName
    && validation.firstNameLastName(payoneerAccountName)
    && validation.onlyLettersWithSpaceOrHyphen(payoneerAccountName);

    const isSomeFileLoading = payoneerFiles.some(file => file.progress);
    const isAllFilesError = payoneerFiles.every(file => file.error);

    const isVerifyBtnDisabled = !isValid || isFetchingFields || isSomeFileLoading || isAllFilesError;

    const isVerifiedOrPending = methodItem.verified || methodItem.pending;
    let showUploadBtn = true;

    if (isVerifiedOrPending || payoneerFiles.length >= 2) {
      showUploadBtn = false;
    }

    const onPayoneerSubmit = (event) => {
      event.preventDefault();
      if (!isValid || !isModified) return;

      const data = [methods.Payoneer, payoneerValue, {
        files: this.cleanUpFiles(payoneerFiles),
        account_name_surname: payoneerAccountName,
        country: payoneerCountry,
      }];


      saveWithInfoMethodValue(...data);
    };

    return (
      <form onSubmit={onPayoneerSubmit} className={classNames('row column row_column_gap_12', { disabled: isVerifiedOrPending, 'opacity-05': methodItem.pending })}>
        <div className="row space-between vertical" style={{ height: '30px' }}>
          <img
            className={classNames(
            'img-payment',
            { pending: methodItem.pending },
            { verified: methodItem.verified },
          )}
            src={payoneer}
            alt=""
            width="68px"
            height={24}
          />
          <div>
            {isModified &&
              <button
                className="btn btn-bright-success"
                type="submit"
                disabled={isVerifyBtnDisabled}
              >
                Verify
              </button>
            }
            {!isModified && this.renderPayoneerStatus()}
          </div>
        </div>
        <input
          type="text"
          name="payoneerAccountName"
          className={classNames('input-text', { 'opacity-05': isVerifiedOrPending })}
          placeholder="Your Payoneer Account Name & Surname"
          value={payoneerAccountName}
          onChange={this.onInputChange}
          disabled={changeLocked}
        />
        <div className="row vertical column_gap_8">
          <input
            type="text"
            name="payoneerValue"
            className={classNames('input-text basis-100', { 'opacity-05': isVerifiedOrPending })}
            placeholder="Enter your Payoneer email"
            value={payoneerValue}
            onChange={this.onInputChange}
            disabled={changeLocked}
            style={{ maxWidth: 'calc(50% - 8px)' }}
          />
          <PaymentMethodCountryList
            countries={countryList}
            selectedCountry={payoneerCountry || 'Your Payoneer Acc. Country'}
            onCountryChange={country => this.setState({ payoneerCountry: country })}
            isNoValue={!payoneerCountry}
          />
        </div>

        {showUploadBtn && (
          <div className="row vertical column_gap_8">
            <UploadButton
              accept="image/*"
              className="btn btn-light"
              multiple={payoneerFiles.length === 0}
              onChange={event => this.onPaymentFileUpload(event, 'Payoneer')}
              disabled={isFetchingFields}
            >
              Attach file(s)
            </UploadButton>
            <a
              href="https://drive.google.com/file/d/1SXtWPZ55uqvSCxIHjPgM0Op1HleMhnGF/view"
              target="_blank"
              rel="noopener noreferrer"
              className="pr10 pl10 pt8 pb8"
            >
              Find more
            </a>
          </div>
        )}
        <PaymentMethodFile
          files={payoneerFiles}
          onDeleteFile={(_s, id) => this.onDeleteFile(id, 'Payoneer')}
          noBtns={isVerifiedOrPending}
          isVerified={methodItem.verified}
        />
      </form>
    );
  }

  renderWireTransfer() {
    const {
      userName, userCountry, countryList, savePayMethodValue, payMethodList = {},
    } = this.props;
    const { showWTWelcomeModal, showWTFormModal } = this.state;
    const WTData = payMethodList.WireTransfer || {};

    const {
      pending, verified, rejected, valid, value: WTValue = {},
    } = WTData;

    if (pending) {
      return (<div className="row vertical left pr10"><Icon className="svg-icon mr8" iconName="watch" />Checking</div>);
    }

    if (verified) {
      return (
        <div className="row vertical left">
          <div className="status-box pr10"><Icon className="svg-icon success" iconName="check-fill" />Verified</div>
        </div>
      );
    }

    const notValidOrReject = rejected || (valid !== undefined && valid === false);
    const isValidButNotVerified = !verified && valid;
    const showAgain = notValidOrReject || isValidButNotVerified;

    const btnText = showAgain ? 'Activate once again' : 'Activate payment method';

    if (showAgain) {
      const {
        province, bank_name, interm_bank_name, bank_country, city, bank_address,
        bank_swift, interm_bank_address, country_of_residency, postal_code,
        beneficiary_address, full_name, interm_bank_account, interm_bank_country,
        interm_bank_swift, bank_account, default_currency, bank_ifsc,
      } = WTValue;
      storage.set('wire-transfer-data', JSON.stringify({
        personalInfo: {
          full_name,
          default_currency,
          country_of_residency,
        },
        bankInfo: {
          bank_account,
          bank_swift,
          bank_country,
          bank_name,
          bank_address,
          bank_ifsc,
          isBankAccountValid: true,
          isBankSwiftValid: true,
          isBankIFSCValid: Boolean(bank_ifsc),
        },
        bankIntermediaryInfo: {
          interm_bank_account,
          interm_bank_swift,
          interm_bank_country,
          interm_bank_name,
          interm_bank_address,
          isIntermBankAccountValid: true,
          isIntermBankSwiftValid: true,
        },
        addressInfo: {
          beneficiary_address,
          city,
          province,
          postal_code,
        },
      }));
    }

    return (
      <div className={classNames('row vertical', { 'space-between': showAgain })}>
        {
          showWTWelcomeModal &&
          <WelcomeWTModal toggleWelcomeWireTransferModal={this.toggleWelcomeWireTransferModal} toggleFormWireTransferModal={this.toggleFormWireTransferModal} />
        }
        {
          showWTFormModal &&
          <WTFormModal toggleFormWireTransferModal={this.toggleFormWireTransferModal} profile={{ userName, userCountry }} countryList={countryList} savePayMethodValue={savePayMethodValue} />
        }
        <button className="btn btn-default transparent" onClick={showAgain ? this.toggleFormWireTransferModal : this.toggleWelcomeWireTransferModal}>{btnText}</button>
        {
          notValidOrReject && <div className="mr12 as-error-text">Rejected</div>
        }
      </div>
    );
  }

  renderCurrentMethodText() {
    const { payMethodList, payMethod } = this.props;
    const methodItem = payMethodList[payMethod === 'Payoneer' ? 'PayoneerEmail' : payMethod];
    const paymentName = getMethodName(payMethod);

    if (paymentName === 'Payoneer' || payMethod === 'Payoneer') {
      const text = `Payoneer (${methodItem.value})`;
      return (<b>{text}</b>);
    }

    return (
      <b>{paymentName} ({methodItem.value.bank_account || methodItem.value})</b>
    );
  }

  render() {
    const {
      changeLocked,
      payMethod, isLoadingPayMethod,
      isDe, isPayMethodError = false,
      availablePayMethods = [],
      payMethodList,
      isDisabledPayment,
      isWriterFromPKProp,
    } = this.props;
    const { showThanksWTModal } = this.state;
    const className = classNames('payment-info-box', { disabled: changeLocked });

    const classNamePAYPAL = classNames('payment-info', { active: payMethod === methods.PAYPAL });
    const classNameSKRILL = classNames('payment-info', { active: payMethod === methods.SKRILL });
    const classNamePayoneer = classNames('payment-info', { active: payMethod === 'Payoneer' });
    const classNameWireTransfer = classNames('payment-info', { active: payMethod === methods.WireTransfer });
    const classNameWise = classNames('payment-info', { active: payMethod === methods.wise });

    return (
      <div>
        {!changeLocked && payMethod &&
          <div className="bg-context">
            Current withdrawal method: {this.renderCurrentMethodText()}
          </div>
        }

        {changeLocked && !isDisabledPayment &&
          <div className="bg-context alert-danger">
            Primary payment method modification will be available after <b>{formatTime(changeLocked, 'd')}</b>
          </div>
        }

        {!payMethod && !isLoadingPayMethod &&
          <div className="bg-context alert-danger">
            You can start working on orders before setting up a payment method. Money will remain on your balance until you're ready to withdraw. We recommend verifying two payment methods for seamless payments.
          </div>
        }
        {
          isPayMethodError && (
            <Modal onClose={this.onToggleWarning} className="modal-sm ta-center">
              <img className="modal-icon" src={alarm} alt="" />
              <h2 className="title mb30">You cannot change <br /> payment method now</h2>
              <button type="button" className="btn btn-bright btn-big" onClick={this.onToggleWarning}>Got it</button>
            </Modal>
          )
        }

        <div className={className}>
          <div className="payment-info payment-info--dropdown payment-info--no-border">
            <div className="row vertical">
              <div className="col-1">
                <b>Primary payment option:</b>
              </div>
              <div className="col-2">
                <div className="row vertical">
                  <div className="col-3">
                    {(availablePayMethods.length > 0 || isLoadingPayMethod) &&
                      <PaymentsTypeDropdown
                        payments={availablePayMethods}
                        onChange={e => this.onSavePayMethod(e)}
                        payMethod={payMethod}
                        disabled={isLoadingPayMethod || changeLocked || isDisabledPayment}
                      />
                    }
                    {!availablePayMethods.length && !isLoadingPayMethod && !changeLocked &&
                      <div className="text-danger">Please set up at least one payment option below</div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            payMethodList[methods.SKRILL] && (
              <div className={classNameSKRILL}>
                <div className="row vertical">
                  <div className="col-1">
                    <img className="img-payment" src={skrill} alt="" width="47px" />
                  </div>
                  <div className="col-2">
                    {this.renderSkrillForm()}
                  </div>
                </div>
              </div>
            )
          }
          {payMethodList[methods.Wise] && (
            <div className={classNameWise}>
              {this.renderWiseForm()}
            </div>
          )}
          {payMethodList[methods.PAYPAL] && (
            <div className={classNamePAYPAL}>
              {this.renderPayPalForm()}
            </div>
          )}
          {
            payMethodList[methods.Payoneer] && (
              <div className={classNamePayoneer}>
                {!isWriterFromPKProp && <WarningBanner paymentMethod="Payoneer" />}
                {this.renderPayoneerForm()}
              </div>
            )
          }
          {
            !isDe && (
              <div className={classNameWireTransfer}>
                <WarningBanner paymentMethod="Wire Transfer" />
                <div className="row vertical">
                  <div className="col-1">
                    <img className="img-payment" src={wireTransfer} alt="" width="68px" />
                  </div>
                  <div className="col-2">
                    {this.renderWireTransfer()}
                  </div>
                </div>
              </div>
            )
          }
        </div>
        {
          showThanksWTModal &&
          <ThanksModal toggleThanksWireTransferModal={this.toggleThanksWireTransferModal} />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user = {} } = state;
  const {
    fetchingFields, pay_methods_list, pay_method, isPayMethodError = false,
    available_pay_methods: availablePayMethods = [],
    _id: useId,
  } = user;
  const isDe = state.user && (state.user.profile_type === 'D');

  return {
    payMethodList: pay_methods_list,
    payMethod: pay_method === 'PayoneerEmail' ? 'Payoneer' : pay_method,
    isLoadingPayMethod: fetchingFields.indexOf('pay_method') > -1,
    isSkrillReqsMet: !!user.name && !!user.country && user.passport_verified && user.profile_residence_verified,
    changeLocked: user.payment_change_locked,
    isDisabledPayment: user.pay_method_disabled,
    isFetchingFields: fetchingFields.length > 0,
    userName: user.name,
    userCountry: user.country,
    countryList: (state.settings && state.settings.countryList) || null,
    isDe,
    isPayMethodError,
    availablePayMethods: availablePayMethods.map((item) => {
      if (item === 'PayoneerEmail') return 'Payoneer';
      return item;
    }),
    isWriterFromPKProp: isWriterFromPK(user),
    useId,
  };
};

const mapDispatchToProps = dispatch => ({
  savePayMethod: event => dispatch(userSetFieldFetch('pay_method', event.target.value)),
  savePayMethodDropdown: (value, oldValue) => dispatch(userSetFieldFetch('pay_method', value, oldValue)),
  savePayMethodValue: (name, value) => dispatch(userSetFieldFetch('set_pay_method', { name, value })),
  saveWithInfoMethodValue: (name, value, info = {}) => dispatch(userSetFieldFetch('set_pay_method', { name, value, info })),
  clearError: () => dispatch(userClearPaymentError()),
  addModalToState: data => dispatch(addModalComponent(data)),
  deleteModalToState: name => dispatch(closeModalComponent(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfoForm);
