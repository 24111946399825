import CircularProgressBar from 'components/CircularProgressBar';
import DeleteFileIcon from 'components/Icon/DeleteFileIcon';

import classNames from 'utils/classNames';

const PaymentMethodFile = ({
  files, onDeleteFile, noBtns, isVerified,
}) => {
  if (!files || files.length === 0) return null;

  const renderFileBtn = (completed, error, progress, id) => {
    if (noBtns) return null;

    if (error || completed) {
      return <DeleteFileIcon onClick={onDeleteFile} name={id} />;
    }

    return <CircularProgressBar percentage={progress} />;
  };

  const renderFile = (name, location, error) => {
    if (isVerified) {
      return (
        <a className="as-link-text" href={location} target="_blank" rel="noreferrer">{name}</a>
      );
    }

    return (
      <p className={classNames(
        'fsMd as-grey-text',
        { 'as-error-text': error },
      )}
      >{name}</p>
    );
  };

  return (
    <div className="row column row_column_gap_8" style={isVerified ? { pointerEvents: 'all', opacity: 1 } : undefined}>
      {files.map((file) => {
        const {
          name, completed, error, progress, id,
          location,
        } = file;
        return (
          <div className="row space-between vertical" key={id}>
            {renderFile(name, location, error)}
            {renderFileBtn(completed, error, progress, id)}
          </div>
        );
      })}
    </div>
  );
};

export default PaymentMethodFile;
